<template>
  <section class="w1314 mh100vh">
    <div class="snavi">
      <!--img class="mr10 ml10" src="@/assets/img/snavhomeicon.png" />
      <img src="@/assets/img/snavicon.png" />
      <span class="ml10 b">{{ $t('front.common.board') }}</span-->
    </div>
    <h2 class="subBanner">{{ $t('front.common.board') }}<!--img src="@/assets/img/subBanner.png" alt=""/--></h2>
    <router-view :key="$route.params"/>
  </section>

</template>

<script>
export default {
  name: 'Board'
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
